import {
  AppstoreAddOutlined,
  ClusterOutlined,
  UnorderedListOutlined,
  RiseOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'

export const confifuration = {
  route: {
    path: '/',
    routes: [
      {
        path: '/game-events',
        name: 'Game Events',
        icon: <AppstoreAddOutlined />,
        component: './Welcome',
      },
      {
        path: '/schemes',
        name: 'Schemes',
        icon: <ClusterOutlined />,
        component: './Schemes',
      },
      {
        path: '/reward',
        name: 'Reward',
        icon: <UnorderedListOutlined />,
        component: './Reward',
      },
      {
        path: '/referral',
        name: 'Referral',
        icon: <ShareAltOutlined />,
        locale: '.Referral',
      },
      {
        path: '/purchased',
        name: 'Purchased Schemes',
        icon: <RiseOutlined />,
        component: './purchased',
      },
      {
        path: '/marketplace',
        name: 'Marketplace',
        icon: <RiseOutlined />,
        component: './marketplace',
      },
      {
        path: '/orders',
        name: 'Orders',
        icon: <RiseOutlined />,
        component: './orders',
      },
    ],
  },
  location: {
    pathname: '/',
  },
}
