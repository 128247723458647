import ProTable, { ActionType } from '@ant-design/pro-table'
import { Ireward, IrewardedGraphData, REWARDEDS } from './query'
import { useQuery } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'
import { columns } from './Columns'
import { Result } from 'antd'
import { getGameEvents } from '../../api'

export type RewardItem = {
  id: string
  player: string
  amount: number
  challengeId: string
  rewardedAt: string
}

export const Rewarded = ({ symbol }: { symbol: string }) => {
  const [rewardedData, setRewardedData] = useState<Ireward[]>([])
  const { loading, data, error } = useQuery<IrewardedGraphData>(REWARDEDS)

  const rewardedWithNames = useCallback(async () => {
    const { data: dataAWS } = await getGameEvents()
    const updatedData =
      data?.rewardeds.map((rewarded) => {
        const event = dataAWS.find((event) => event.id === rewarded.challengeId)

        if (event) {
          rewarded.challengeId = event.name
        }
        return rewarded
      }) ?? []
    setRewardedData(updatedData)
  }, [data])

  useEffect(() => {
    rewardedWithNames()
  }, [rewardedWithNames])

  const actionRef = useRef<ActionType>()
  if (error)
    return (
      <Result
        status="error"
        title="[The Graph] Connection Issue"
        subTitle={error?.message}
      />
    )
  return (
    <ProTable<Ireward>
      loading={loading}
      rowKey="id"
      search={false}
      dataSource={rewardedData}
      actionRef={actionRef}
      columns={columns(symbol)}
      headerTitle="Rewards"
      tooltip={'Smart Contract Data, Indexed by TheGraph'}
      options={{
        density: true,
        fullScreen: true,
        search: false,
        reload: false,
        setting: true,
      }}
      pagination={{
        showQuickJumper: true,
      }}
      dateFormatter="string"
    />
  )
}
