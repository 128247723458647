import axios from 'axios'
import { IAWSScheme } from '../features/schemes/scheme'

const API_URL = process.env.REACT_APP_API || ''

export const getSchemes = async () => {
  const response = await axios.get<IAWSScheme[]>(`${API_URL}/schemes`)
  return response
}

export const deleteScheme = async (id: string) => {
  const response = await axios.delete<string>(`${API_URL}/scheme/${id}`)
  return response.data
}

export const editScheme = async (awsScheme: IAWSScheme) => {
  const response = await axios.put<IAWSScheme>(`${API_URL}/scheme`, awsScheme, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export const createScheme = async (data: IAWSScheme) => {
  const response = await axios.post<IAWSScheme[]>(`${API_URL}/scheme`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}
