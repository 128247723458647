import { ProColumns } from '@ant-design/pro-table'
import { RewardItem } from './Rewarded'
import { utils } from 'ethers'
import { Ireward } from './query'

export const columns = (symbol: string) => {
  const data: ProColumns<Ireward>[] = [
    {
      title: 'The Graph ID ',
      dataIndex: 'id',
      hideInSearch: true,
      editable: false,
      hideInTable: true,
    },
    {
      title: 'Challenge ID ',
      dataIndex: 'challengeId',
      hideInSearch: true,
      editable: false,
    },
    {
      title: 'Player Wallet',
      dataIndex: 'player',
      sorter: (a, b) => a.player.localeCompare(b.player),
      ellipsis: true,
      copyable: true,
      search: false,
    },
    {
      title: 'Token Amount',
      dataIndex: 'amount',
      sorter: (a, b) => Number(a.amount) - Number(b.amount),
      search: false,
      render: (text) => {
        const tokenBalance = utils.formatUnits(text as string, 10)
        return `${tokenBalance} (${symbol})`
      },
    },
    {
      title: 'Rewarded At',
      dataIndex: 'rewardedAt',
      valueType: 'fromNow',
      hideInTable: false,
      editable: false,
      sorter: (a, b) =>
        new Date(Number(a.rewardedAt) * 1000).getTime() -
        new Date(Number(b.rewardedAt) * 1000).getTime(),
      render: (date) => {
        //@ts-ignore
        return new Date(Number(date.props.text) * 1000).toUTCString()
      },
    },
  ]
  return data
}
