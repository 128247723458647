import { useRef } from 'react'
import { ActionType } from '@ant-design/pro-table'
import ProTable from '@ant-design/pro-table'
import { ManageEventModal } from './ManageEventModal'
import { columns } from './Columns'
import { ExpandedRowRender } from './ExpandedRowRender'
import {
  getSchemes,
  deleteGameEvent,
  editGameEvent,
  getChallenges,
} from '../../api'
import { sanitizeData } from './sanitizeData'
import { getGameEvents } from '../../api'
import { DownloadCSVButton } from '../../components/DownloadCSV/DownloadButton'

export const Table = ({ name, symbol }: { name: string; symbol: string }) => {
  const actionRef = useRef<ActionType>()

  return (
    <ProTable<IEvent>
      rowKey="id"
      actionRef={actionRef}
      columns={columns(name, symbol, actionRef)}
      headerTitle="Game Events"
      search={{
        labelWidth: 'auto',
        filterType: 'query',
      }}
      pagination={{
        showQuickJumper: true,
      }}
      editable={{
        type: 'single',
        onSave: async (_, data) => {
          console.log(data)
          //retrive and filter schemes
          //retrieve and filter challenges
          //send to api
          const { data: schemes } = await getSchemes()
          const challenges = await getChallenges()

          await editGameEvent({
            ...data,
            schemes: data.schemes.map(
              (schemeName) =>
                schemes.find(({ name }) => name === schemeName)?.id ??
                schemeName,
            ),
            challengeId:
              challenges.find(({ name }) => name === data.challengeId)?.id ??
              data.challengeId,
          })
        },
        onDelete: async (rowKey) => {
          await deleteGameEvent(rowKey as string)
        },
      }}
      expandable={{ expandedRowRender: ExpandedRowRender }}
      dateFormatter="string"
      request={async (params = {}) => {
        const request = await getGameEvents()
        const schemesData = await getSchemes()
        const challengesData = await getChallenges()
        const updatedData = sanitizeData(
          request.data,
          schemesData.data,
          challengesData,
          params,
        )
        return { ...request, data: updatedData }
      }}
      toolBarRender={() => [
        <ManageEventModal
          action={actionRef}
          type="create"
          name={name}
          symbol={symbol}
        />,
        <DownloadCSVButton
          key="download-btn"
          data={getGameEvents}
          filename="game-event-report"
        />,
      ]}
    />
  )
}