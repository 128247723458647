import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActionType } from '@ant-design/pro-table'
import ProTable from '@ant-design/pro-table'
import { ManageEventModal } from './ManageEventModal'
import { columns } from './Columns'
import { getProducts } from '../../api'
import { sanitizeData } from './sanitizeData'
import { editProducts, deleteProducts } from '../../api'
import { DownloadCSVButton } from '../../components/DownloadCSV/DownloadButton'
import { ExpandedRowRender } from './ExpandedRowRender'
import { useQuery } from '@apollo/client'
import { SCHEMES, ISchemesData } from './query'
import { Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { ICreateScheme } from '../marketplace/scheme'
import { ProductDataTable } from '../marketplace/scheme'

interface IProps extends ICreateScheme {
  symbol: string
}

export const Table = ({ symbol, createSmartContractScheme }: IProps) => {
  const actionRef = useRef<ActionType>()
  const { loading, data, refetch } = useQuery<ISchemesData>(SCHEMES)
  const [dataTable, setScheme] = useState<ProductDataTable[]>([])
  const [tableLoading, setTableLoading] = useState(false)

  useEffect(() => {
    setTableLoading(loading)
    return () => {
      setTableLoading(false)
    }
  }, [loading])

  const fetchAWSschmes = useCallback(async () => {
    const awsSchemes = await getProducts()
    //@ts-ignore
    const normalizedSchemes: ProductDataTable[] =
      data?.schemes.map((scheme) => {
        const schemeMeta = awsSchemes.data.find(
          (awsScheme) => awsScheme.id === scheme.schemeId,
        )
        if (!schemeMeta)
          return { ...scheme, name: '', description: '', logoSrc: '' }
        return {
          ...scheme,
          ...schemeMeta,
        }
      }) ?? []
  
    // Filter normalizedSchemes based on levelupscreenid being "marketplace"
    const marketplaceSchemes = normalizedSchemes.filter(
      (scheme) => scheme.levelupscreenid === "marketplace"
    )
  
    setScheme(marketplaceSchemes)
  }, [data])
  

  const reloadTable = useCallback(async () => {
    setTableLoading(true)
    await refetch()
    await fetchAWSschmes()
    setTableLoading(false)
  }, [refetch, fetchAWSschmes])

  useEffect(() => {
    reloadTable()
  }, [reloadTable])

  return (
    <ProTable<ProductDataTable>
      rowKey="id"
      loading={tableLoading}
      actionRef={actionRef}
      dataSource={dataTable}
      columns={columns(
        symbol,
        createSmartContractScheme,
        reloadTable,
        actionRef,
      )}
      headerTitle="Products"
      expandable={{ expandedRowRender: ExpandedRowRender }}
      search={false}
      pagination={{
        showQuickJumper: true,
      }}
      dateFormatter="string"
      tooltip={'Smart Contrat + AWS Cloud'}
      options={{
        density: true,
        fullScreen: true,
        search: false,
        reload: false,
        setting: true,
      }}
      toolBarRender={() => [
        <ManageEventModal
          key="manageEvent"
          action={actionRef}
          type="create"
          symbol={symbol}
          createSmartContractScheme={createSmartContractScheme}
          reloadTable={reloadTable}
        />,
        <Button
          key="reload"
          disabled={loading}
          icon={<ReloadOutlined />}
          onClick={reloadTable}
        />,
      ]}
    />
  )
}
