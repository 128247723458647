import React, { useCallback, useEffect, useState } from 'react';
import Decimal from 'decimal.js';
import { Button, notification, Upload, Form, Input } from 'antd';
import ProForm, {
  ModalForm,
  ProFormText,
  ProFormDigit,
  ProFormSelect,
  ProFormDatePicker,
  ProFormSwitch,
  ProFormList,
  ProFormDateTimePicker,
} from '@ant-design/pro-form';

import {
  PlusOutlined,
  EditOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
// Import Amplify and Storage
import { Storage } from 'aws-amplify';
import { ActionType } from '@ant-design/pro-table';
import { createScheme, editScheme } from '../../api';
import { SchemeDataTable } from '../schemes/scheme';
import { ICreateScheme } from '../schemes/scheme';

const S3_BUCKET = `https://overwolfadministratieccfcf8a068841dbb81ccc4db84160603-staging.s3.eu-central-1.amazonaws.com/public/`



interface IProps extends ICreateScheme {
  action?: React.MutableRefObject<ActionType | undefined>;
  type: 'create' | 'edit';
  data?: SchemeDataTable;
  symbol: string;
  reloadTable: () => Promise<void>;
}

interface ISchemeOptions {
  value: 1 | 2 | 3 | 4;
  label: string;
}

const schemesOptions: ISchemeOptions[] = [
  {
    value: 1,
    label: 'addition',
  },
  {
    value: 3,
    label: 'multiplier',
  },
  {
    value: 2,
    label: 'percentage',
  },
  {
    value: 4,
    label: 'services',
  },
];

interface ICustomRequest {
  file: any;
  onProgress?: (event: { percent: number }) => void;
  onError?: (event: ProgressEvent, body?: any) => void;
  onSuccess?: (body: any, xhr: XMLHttpRequest) => void;
}

export const ManageEventModal = ({
  action,
  type,
  data,
  symbol,
  createSmartContractScheme,
  reloadTable,
}: IProps) => {
  const [currentType, setCurrentGameIdType] = useState('addition');
  const [imageUrl, setImageUrl] = useState<string>('no-image');

  useEffect(() => {
    if (type === 'edit' && data?.logoSrc) setImageUrl(data.logoSrc);
    return () => {
      setCurrentGameIdType('addition');
      setImageUrl('no-image');
    };
  }, [data, type]);

  const customRequest = async ({
    file,
    onError,
    onSuccess,
    onProgress,
  }: ICustomRequest) => {
    try {
      const fileType = file?.type;
      const fileName = file?.name;

      const result = (await Storage.put(fileName, file, {
        acl: 'public-read',
        level: 'public',
        contentType: fileType,
        progressCallback: onProgress,
      })) as Record<string, string>;

      onSuccess?.(result, file);
      setImageUrl(`${S3_BUCKET}${result.key}`);
    } catch (err) {
      onError?.(err as ProgressEvent<EventTarget>, file);
      console.log(err);
    }
  };

  const createSchemeForm = useCallback(
    async (dataForm: SchemeDataTable) => {
      try {
        const awsScheme = {
          id: data?.id ?? '',
          name: dataForm.name,
          logoSrc: imageUrl,
          description: dataForm.description,
          overwolfInfo: dataForm.overwolfInfo,
          levelupscreenid: dataForm.levelupscreenid,
        };
        // console.log({ dataForm
        // console.log({ data })
  
        if (
          type === 'edit' &&
          new Date(Number(data?.validThru)).getTime() ===
            new Date(dataForm.validThru).getTime() &&
          Number(data?.expirationDays) === Number(dataForm.expirationDays) &&
          data?.purchaseAmount === dataForm.purchaseAmount &&
          data?.schemeType === dataForm.schemeType &&
          data?.schemeValue === dataForm.schemeValue
        ) {
          await editScheme(awsScheme)
          await reloadTable()
          return true
        } else if (type === 'edit') {
          await createSmartContractScheme(
            {
              //@ts-ignore
              id: (Number(data?.id) as unknown) as number,
              validThru: new Date(dataForm.validThru).getTime(),
              schemeType: dataForm.schemeType,
              schemeValue: dataForm.schemeValue,
              purchaseAmount: (parseFloat(dataForm.purchaseAmount) * 1e10).toString(),
              expirationDays: dataForm.expirationDays,
            },
            'update',
          )
          await editScheme(awsScheme)
          await reloadTable()
          return true
        } else {
          const schemeID = await createSmartContractScheme(
            {
              validThru: new Date(dataForm.validThru).getTime(),
              schemeType: dataForm.schemeType,
              schemeValue: dataForm.schemeValue,
              purchaseAmount: (parseFloat(dataForm.purchaseAmount) * 1e10).toString(),
              expirationDays: dataForm.expirationDays,
            },
            'add',
          )
  
          if (typeof schemeID === 'string' && schemeID.length > 0) {
            await createScheme({
              id: schemeID,
              name: dataForm.name,
              description: dataForm.description,
              logoSrc: imageUrl,
              levelupscreenid: dataForm.levelupscreenid,
              overwolfInfo: dataForm.overwolfInfo ?? [],
            })
          }
          await reloadTable()
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    [action, data, type, imageUrl, reloadTable, createSmartContractScheme],
  )  

  return (
    <ModalForm<SchemeDataTable>
      title={`${type === 'create' ? 'Create' : 'Edit'} Scheme`}
      trigger={
        <Button
          type={type === 'create' ? 'primary' : 'default'}
          size={type === 'create' ? 'middle' : 'small'}
        >
          {type === 'create' ? <PlusOutlined /> : <EditOutlined />}
          {type === 'create' ? 'New' : 'Edit'}
        </Button>
      }
      lang={'en'}
      modalProps={{
        okText: 'Save',
        destroyOnClose: true,
      }}
      onFinish={async (values) => {
        const result = await createSchemeForm(values)

        if (result)
          notification.success({
            message:
              type === 'create'
                ? 'The scheme was successfully created'
                : 'The scheme was successfully edited',
            duration: 5000,
          })
        else
          notification.error({
            message: '3 - The schema was not created correctly',
            description: `error  ${
              type === 'create' ? 'creating' : 'editing'
            } scheme ${values.name} , please try again`,
            duration: 5000,
          })
        return result
      }}
    >

      <ProForm.Group>
        <ProForm.Group>
    <ProFormText
      initialValue={type === 'edit' && data?.name ? data.name : ''}
      preserve={false}
      width="md"
      name="name"
      label="Name"
      placeholder="Scheme name..."
      rules={[{ required: true, message: 'Please select a scheme!' }]}
    />
  </ProForm.Group>
  
        <ProFormText
          initialValue={
            type === 'edit' && data?.description ? data.description : ''
          }
          preserve={false}
          width="md"
          name="description"
          label="Description"
          placeholder="Scheme event description ..."
          rules={[{ required: true, message: 'Please select a description!' }]}
        />
          <ProFormSelect
  initialValue={
    type === 'edit' && data?.levelupscreenid ? data.levelupscreenid : 'levelup'
  }
  preserve={false}
  width="md"
  name="levelupscreenid"
  label="Screen Filtering"
  placeholder="Select a screenid"
  rules={[{ required: true, message: 'Please select a  screenid!' }]}
  options={[
    { label: 'LevelUP', value: 'levelup' },
    { label: 'Guilds', value: 'guilds' },
    { label: 'Marketplace', value: 'marketplace' },
    { label: 'Do not display', value: 'none' },
  ]}
/>

      </ProForm.Group>
      <ProForm.Group>
        <Upload
          // disabled
          defaultFileList={
            type === 'edit' && data?.logoSrc && data.logoSrc !== 'no-image'
              ? [
                  {
                    uid: '-1',
                    name: 'loading...',
                    status: 'done',
                    url: data?.logoSrc,
                    thumbUrl: data?.logoSrc,
                  },
                ]
              : undefined
          }
          onPreview={({ name }) => {
            window.open(`${S3_BUCKET}${name}`, '__blank')
          }}
          onRemove={async ({ name }) => {
            console.log(`delete ... ${name}`)
            await Storage.remove(name)
            setImageUrl(`no-image`)
          }}
          accept="image/*"
          maxCount={1}
          //@ts-ignore
          customRequest={customRequest}
          listType="picture-card"
          name="logoSrc"
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
        <ProFormSelect
          initialValue={
            type === 'edit' && data?.schemeType ? data.schemeType : ''
          }
          preserve={false}
          options={schemesOptions}
          width="sm"
          name="schemeType"
          label="Type"
          rules={[{ required: true, message: 'Please select a type!' }]}
          fieldProps={{
            onSelect: setCurrentGameIdType,
            onClear: () => setCurrentGameIdType('multiplier'),
          }}
        />
        {currentType === 'services' ? (
          <ProFormSwitch
            initialValue={Boolean(data?.schemeValue || false)}
            key={'switch'}
            name="schemeValue"
            label="Value"
            tooltip="false / true"
          />
        ) : (
          <ProFormDigit
          key={'digit'}
          initialValue={data?.schemeValue ?? 0}
          preserve={false}
          width="sm"
          min={0}
          rules={[{ required: true, message: 'Please select a value!' }]}
          name="schemeValue"
          label="Value"
          />
        )}
        <ProFormDigit
          key={'digit'}
          initialValue={data?.purchaseAmount ?? 0}
          preserve={false}
          width="sm"
          min={0}
          rules={[{ required: true, message: 'Please select a value!' }]}
          name="purchaseAmount"
          label={`Purchase Amount (${symbol})`}
        />
        <ProFormDateTimePicker
          initialValue={
            type === 'edit' && typeof data?.validThru === 'string'
              ? new Date(Number(data?.validThru)).toISOString()
              : new Date().toISOString()
          }
          preserve={false}
          width="sm"
          name="validThru"
          label="Valid Thru"
          rules={[{ required: true, message: 'Please select a date!' }]}
        />
        <ProFormDigit
          initialValue={
            type === 'edit' && Number(data?.expirationDays)
              ? data?.expirationDays
              : 0
          }
          preserve={false}
          min={0}
          name="expirationDays"
          label="Expiration Days"
          rules={[{ required: true, message: 'Please select a date!' }]}
        />{' '}
        <ProForm.Group
          title="Overwolf Marketplace Info"
          tooltip="These items are displayed in the purchase card and in the current order."
        >
          <div style={{ maxHeight: 200, overflowY: 'auto' }}>
            <ProFormList
              initialValue={
                type === 'edit' && data?.overwolfInfo ? data.overwolfInfo : []
              }
              copyIconProps={{
                tooltipText: 'Copy',
              }}
              deleteIconProps={{
                tooltipText: 'Delete',
              }}
              name="overwolfInfo"
              creatorButtonProps={{
                position: 'bottom',
                creatorButtonText: 'Building a info line',
              }}
            >
              <ProFormText
                initialValue={
                  type === 'edit' && data?.description ? data.description : ''
                }
                width="md"
                preserve={false}
                name="line"
                placeholder="card description ..."
                rules={[
                  { required: true, message: 'Please select a description!' },
                ]}

                
              />
            </ProFormList>
          </div>
        </ProForm.Group>
      </ProForm.Group>
    </ModalForm>
  )
}
