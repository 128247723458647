import { useState, useEffect } from 'react';
import { Input, Button, Typography, Form, Table, Space, Popconfirm, message, Modal } from 'antd';
import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API || '';

const { Text } = Typography;

interface ReferralItem {
  referralId: string;
  referralCode: string;
  targetUrl: string;
  numberOfReferrals: number;
  referralData?: {
    M: {
      campaign: {
        S: string;
      };
    };
  };
}


export const ReferralPage = () => {
  const [referralCode, setReferralCode] = useState<string>();
  const [referralLink, setReferralLink] = useState<string>();
  const [referralData, setReferralData] = useState<ReferralItem[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState<string>();
  const [form] = Form.useForm();

  const fetchReferralData = async () => {
    try {
      const response = await axios.get(`${API_URL}/referral`);
  
      if (response.data && response.data.body) {
        const rawData: any[] = JSON.parse(response.data.body);
  
        const type1Data = rawData
          .filter((item: any) => item.referralCode && item.targetUrl)
          .map((item: any) => ({
            referralId: item.referralId.S,
            referralCode: item.referralCode.S,
            targetUrl: item.targetUrl.S,
            numberOfReferrals: 0, 
            referralData: undefined,
          }));
  
        const type2Data = rawData
          .filter((item: any) => item.referralData && item.referralData.M && item.referralData.M.campaign && item.referralData.M.campaign.S)
          .map((item: any) => ({
            campaign: item.referralData.M.campaign.S,
          }));
  
        const referralCounts = _.countBy(type2Data, 'campaign');
  
        const data = type1Data.map((item: any) => ({
          referralId: item.referralId,
          referralCode: item.referralCode,
          targetUrl: item.targetUrl,
          numberOfReferrals: referralCounts[item.referralCode] || 0,
          referralData: item.referralData,
        }));
  
        setReferralData(data);
      } else {
        console.log('Unexpected data structure');
      }
    } catch (error) {
      console.log(error);
      setReferralData([]);
    }
  };
  
  
  useEffect(() => {
    fetchReferralData();
  }, []);

  const deleteReferral = async (id: string) => {
    try {
      await axios.delete(`${API_URL}/referral/${id}`);
      message.success('Referral deleted successfully');
      fetchReferralData();
    } catch (error) {
      console.error(error);
      message.error('Failed to delete referral');
    }
  };

  const onFinish = async (values: any) => {
    const { referralCode } = values;
    const targetUrl = `https://download.overwolf.com/install/Download?Name=GamersXP&ExtensionId=nleodhhgmhkknpdhlgaflniojdabmanmjimmbndm&Channel=web_dl_btn&utm_campaign=${referralCode}`;

    try {
      await axios.post(`${API_URL}/referral`, { referralCode, targetUrl });
      setReferralLink(targetUrl);
      setReferralCode(referralCode);
      fetchReferralData();
    } catch (error) {
      console.error(error);
    }
  };

  const onEditFinish = async (values: any) => {
    const { referralCode } = values;
    const targetUrl = `https://download.overwolf.com/install/Download?Name=GamersXP&ExtensionId=nleodhhgmhkknpdhlgaflniojdabmanmjimmbndm&Channel=web_dl_btn&utm_campaign=${referralCode}`;

    try {
      await axios.put(`${API_URL}/referral`, { referralCode, targetUrl });
      message.success('Referral updated successfully');
      fetchReferralData();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error('Failed to update referral');
    }
  };

  const columns = [
    {
      title: 'Referral Code',
      dataIndex: 'referralCode',
      key: 'referralCode',
      render: (text: string, record: ReferralItem) => {
        const referralCode = record.referralCode;
        const campaign = record.referralData?.M?.campaign?.S;
  
        return referralCode || campaign || null;
      },
    },
    {
      title: 'Target URL',
      dataIndex: 'targetUrl',
      key: 'targetUrl',
      render: (text: string, record: ReferralItem) => {
        const targetUrl = record.targetUrl;
        const campaign = record.referralData?.M?.campaign?.S;
  
        return targetUrl || (campaign ? null : '');
      },
    },
    {
      title: 'No of referrals',
      dataIndex: 'numberOfReferrals',
      key: 'numberOfReferrals',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: ReferralItem) => (
        <Space size="middle">
          <a
            onClick={() => {
              setEditId(record.referralId);
              form.setFieldsValue({ referralCode: record.referralCode });
              setIsModalVisible(true);
            }}
          >
            Edit
          </a>
          <Popconfirm
            title="Are you sure you want to delete this referral?"
            onConfirm={() => deleteReferral(record.referralId)}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];  

  return (
    <div>
      <Form onFinish={onFinish}>
        <Form.Item
          label="Referral Code"
          name="referralCode"
          rules={[{ required: true, message: 'Please input the referral code!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Generate Link
          </Button>
        </Form.Item>
        <Form.Item>
          {referralLink && (
            <Text copyable={{ text: referralLink }}>Your referral link: {referralLink}</Text>
          )}
        </Form.Item>
      </Form>
      <Table columns={columns} dataSource={referralData} rowKey="referralId" />

      <Modal
        title="Edit Referral"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={onEditFinish}
        >
          <Form.Item
            label="Referral Code"
            name="referralCode"
            rules={[{ required: true, message: 'Please input the referral code!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};