import React, { useState } from 'react';
import { Button } from 'antd';
import { createOrders, editOrders, deleteOrders } from '../api/orders';
import { IAWSOrders } from '../features/orders/orders';
import Table from '../features/orders/Table';
import ManageEventModal from '../features/orders/ManageEventModal';

const OrdersPage: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<IAWSOrders | undefined>(undefined);

  const handleAdd = () => {
    setCurrentOrder(undefined);
    setVisible(true);
  };

  const handleEdit = (order: IAWSOrders) => {
    setCurrentOrder(order);
    setVisible(true);
  };

  const handleDelete = async (scheme: IAWSOrders) => {
    const response = await deleteOrders(scheme.orderId);
    if (response) {
      // Handle deletion - update state or perform other operations based on response
    }
  };

  const createIWSScheme = async (
    scheme: IAWSOrders,
    actionType: 'add' | 'edit' | 'delete',
  ): Promise<void> => {
    switch (actionType) {
      case 'add':
        await createOrders(scheme);
        break;
      case 'edit':
        await editOrders(scheme);
        break;
      case 'delete':
        await deleteOrders(scheme.orderId);
        break;
      default:
        break;
    }
  };  


  return (
    <div>
      <Button type="primary" onClick={handleAdd}>
        Add Order
      </Button>
      <Table onEdit={handleEdit} onDelete={handleDelete} />
      {visible && (
        <ManageEventModal
          visible={visible}
          onClose={() => setVisible(false)} // Removed the onCancel prop
          currentOrder={currentOrder}
          createIWSScheme={createIWSScheme}
        />
      )}
    </div>
  );
};

export default OrdersPage;