import { Table } from '../features/schemes/Table'
import { ICreateScheme } from '../features/schemes/scheme'

interface IProps extends ICreateScheme {
  symbol: string
}

export function SchemesPage({ createSmartContractScheme, symbol }: IProps) {
  return (
    <>
      <Table
        symbol={symbol}
        createSmartContractScheme={createSmartContractScheme}
      />
    </>
  )
}
