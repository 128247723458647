import { useState } from 'react'
import {
  Avatar,
  Button,
  Result,
  Space,
  Menu,
  Dropdown,
  Descriptions,
  Tag,
  Typography,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import ProLayout, { PageContainer } from '@ant-design/pro-layout'
import { confifuration } from './_defaultProps'
import { EventsPage } from './pages/Events'
import { SchemesPage } from './pages/Schemes'
import { RewardPage } from './pages/Reward'
import { ReferralPage } from './pages/Referral';
import { MarketplacePage } from './pages/Marketplace'
import OrdersPage  from './pages/Orders'
import { Auth } from 'aws-amplify'
import GTIcon from './assets/GT-Icon.svg'
import { useWallet } from './features/wallet'
import { BigNumber, ethers } from 'ethers'
import { ISmartContractScheme } from './features/schemes/scheme'
import { createOrders } from './api/orders'

const { REACT_APP_CONTRACT_ADDRESS: CONTRACT } = process.env


const Content = ({
  address,
  selectedChainId,
  balance,
  token,
}: {
  address: string
  selectedChainId: number
  balance: BigNumber
  token: {
    value?: BigNumber
    name?: string
    symbol?: string
    decimals?: number
    loading: boolean
    isAdmin: boolean
    isDailyOperation: boolean
  }
}) => (
  <Descriptions size="middle" column={2}>
    <Descriptions.Item label="Wallet ID">
      <Typography.Text copyable strong>
        {address}
      </Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="Network">
      <Tag color={selectedChainId === 137 ? 'purple' : 'default'}>
        Polygon Mainnet
      </Tag>
    </Descriptions.Item>
    <Descriptions.Item label="Token Contract">
      <Typography.Text copyable strong>
        {CONTRACT}
      </Typography.Text>
    </Descriptions.Item>

    <Descriptions.Item label="Matic ">
      <a>{`${parseFloat(ethers.utils.formatEther(balance)).toPrecision(
        4,
      )}   Ξ`}</a>{' '}
    </Descriptions.Item>

    <Descriptions.Item label="Wallet Roles">
      <Tag color={token.isAdmin ? 'success' : 'default'}>Admin</Tag>
      <Tag color={token.isDailyOperation ? 'success' : 'default'}>
        Daily Operation
      </Tag>
    </Descriptions.Item>
    <Descriptions.Item
      label={`${token.name ?? '...'} (${token.symbol ?? '...'})`}
    >
      <a>{token.value ? token.value.toString() : '...'}</a>
    </Descriptions.Item>
  </Descriptions>
)

const CurrentPage = ({
  page,
  isValidRole,
  connect,
  name,
  symbol,
  createScheme,
}: {
  page: string
  isValidRole: boolean
  connect: () => void
  name: string
  symbol: string
  createScheme: (
    scheme: ISmartContractScheme,
    type: 'add' | 'update',
  ) => Promise<string | undefined>
}) => {
  if (!isValidRole)
    return (
      <Result
        status="403"
        style={{
          height: '100%',
        }}
        title="......."
        subTitle={`Sorry, you are not authorized to access this page. with current active contract ${CONTRACT}`}
        extra={
          <Button type="primary" onClick={connect}>
            Connect a valid Wallet (Admin Role or Daily Operation Role)
          </Button>
        }
      />
    )
    switch (page) {
      case '/game-events':
        return <EventsPage name={name} symbol={symbol} />
      case '/schemes':
        return (
          <SchemesPage createSmartContractScheme={createScheme} symbol={symbol} />
        )
      case '/reward':
        return <RewardPage symbol={symbol} />
      case '/referral':
        return <ReferralPage />
      case '/marketplace':
        return (
          <MarketplacePage createSmartContractScheme={createScheme} symbol={symbol} />
        )
        case '/orders':
        return (
          <OrdersPage />
        )
      default:
        return (
          <Result
            status="404"
            style={{
              height: '100%',
            }}
            title="......."
            subTitle="Sorry, This resource is not working yet"
          />
        )
    }
  }

export const Layout = () => {
  const [pathname, setPathname] = useState('/game-events')
  const {
    loadWeb3Modal,
    logoutOfWeb3Modal,
    address,
    selectedChainId,
    balance,
    ...token
  } = useWallet()
  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <ProLayout
        {...confifuration}
        navTheme="dark"
        location={{
          pathname,
        }}
        fixSiderbar
        logo={GTIcon}
        title="GamersXP"
        menuFooterRender={(props) => {
          return (
            <div
              style={{
                lineHeight: '48rpx',
                display: 'flex',
                height: 48,
                color: 'rgba(255, 255, 255, 0.65)',
                alignItems: 'center',
              }}
            >
              <img
                alt="gt-logo"
                src={GTIcon}
                style={{
                  width: 16,
                  height: 16,
                  margin: '0 16px',
                  marginRight: 10,
                }}
              />
              {!props?.collapsed && 'Admin System'}
            </div>
          )
        }}
        onMenuHeaderClick={(e) => console.log(e)}
        menuItemRender={(item, dom) => (
          <div
            onClick={() => {
              setPathname(item.path || '/welcome')
            }}
          >
            {dom}
          </div>
        )}
        rightContentRender={() => (
          <div>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key={'1'}
                    onClick={async () => {
                      await loadWeb3Modal()
                    }}
                  >
                    Connect Wallet
                  </Menu.Item>
                  <Menu.Item
                    key={'2'}
                    onClick={async () => {
                      await logoutOfWeb3Modal()
                    }}
                  >
                    Disconnect Wallet
                  </Menu.Item>
                  <Menu.Item
                    key={'3'}
                    onClick={async () => {
                      await Auth.signOut()
                      window.location.reload()
                    }}
                  >
                    Sign Out AWS
                  </Menu.Item>
                </Menu>
              }
              placement="topRight"
              arrow
            >
              <Avatar shape="square" size="small" icon={<UserOutlined />} />
            </Dropdown>
          </div>
        )}
      >
        <PageContainer
          content={
            <Content
              address={address}
              selectedChainId={selectedChainId}
              balance={balance}
              token={token}
            />
          }
          extraContent={
            <Space size={24}>
              <br />
            </Space>
          }
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => {
                window.open(
                  `https://polygonscan.com/address/${CONTRACT}`,
                  '_blank',
                )
              }}
            >
              Polygon Scan: Matic Mainnet
            </Button>,
            <Button key="3" disabled>
              Overwolf App Store
            </Button>,
            <Button
              key="2"
              onClick={() =>
                window.open('https://console.overwolf.com/', '__blank')
              }
            >
              Overwolf Admin Console
            </Button>,
          ]}
        >
          <div
            style={{
              height: '120vh',
            }}
          >
            <CurrentPage
              page={pathname}
              isValidRole={
                token?.isAdmin ||
                token?.isDailyOperation ||
                process.env.NODE_ENV === 'development'
              }
              connect={async () => await loadWeb3Modal()}
              name={token?.name ?? '...'}
              symbol={token?.symbol ?? '...'}
              createScheme={token.createScheme}
            />
          </div>
        </PageContainer>
      </ProLayout>
    </div>
  )
}
