import { notification } from 'antd'
import { BigNumber, ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import ABI from './contract.abi.json'
import { ISmartContractScheme } from '../schemes/scheme'
import Decimal from 'decimal.js';

const { REACT_APP_CONTRACT_ADDRESS: CONTRACT = '' } = process.env

export const useContract = (
  injectedProvider: ethers.providers.Web3Provider,
  walletID: string,
) => {
  const [contract, setContract] = useState<ethers.Contract | null>(null)
  const [meta, setMeta] = useState<{
    name: string
    symbol: string
    value: BigNumber
    totalSupply: BigNumber
  } | null>(null)

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      console.log({ provider })
      setContract(new ethers.Contract(CONTRACT, ABI, provider))
    }
  }, [injectedProvider])

  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
  }

  const createScheme = useCallback(
    async (
      scheme: ISmartContractScheme,
      type: 'add' | 'update',
    ): Promise<string | undefined> => {
      if (typeof window.ethereum !== 'undefined') {
        console.log('signed', type, scheme)
        await requestAccount()
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const signedContract = new ethers.Contract(CONTRACT, ABI, signer)
        //const schemeValue = new Decimal(scheme.schemeValue).times(new Decimal('1e8'))
        //const purchaseAmount = new Decimal(scheme.purchaseAmount)

        const transation =
          type === 'add'
            ? await signedContract.addScheme(
                scheme.schemeType,
                scheme.schemeValue,
                scheme.purchaseAmount,
                scheme.validThru,
                scheme.expirationDays,
              )
            : await signedContract.updateScheme(
                scheme.id,
                scheme.schemeType,
                scheme.schemeValue,
                scheme.purchaseAmount,
                scheme.validThru,
                scheme.expirationDays,
              )
        notification.warning({
          message: '1 - Transaction being processed ... (NOT CLOSE THE WINDOW)',
          description: `It may take a while for the information to be confirmed in all blocks |
            from: ${transation.from} |
            to: ${transation.to} |
            transaction hash: ${transation.hash}
          `,
          duration: 5000,
        })

        const response = await transation.wait()

        console.log(response)
        console.log(
          'NEW SCHEME ID ',
          response?.events?.[0]?.args?.schemeId.toString(),
        )
        notification.warning({
          message: '2 - Transaction being confirmed ... (NOT CLOSE THE WINDOW)',
          description: `await data (image, name and description) to be saved in the AWS cloud`,
          duration: 5000,
        })
        return response?.events?.[0]?.args?.schemeId?.toString()
      }
    },
    [],
  )

  useEffect(() => {
    async function fetchMeta() {
      if (contract === null) return

      try {
        const [
          name,
          symbol,
          value,
          totalSupply,
          schemes,
          ADDITION_SCHEME,
          PERCENTAGE_SCHEME,
          MULTIPLIER_SCHEME,
        ] = await Promise.all([
          contract.name(),
          contract.symbol(),
          walletID.length > 0
            ? contract?.balanceOf(walletID)
            : Promise.resolve(BigNumber.from(0)),
          contract.totalSupply(),
          contract.getSchemes(),
          contract.ADDITION_SCHEME(),
          contract.PERCENTAGE_SCHEME(),
          contract.MULTIPLIER_SCHEME(),
        ])
        console.log(
          `name: ${name},balance: ${value.toString()}, totalSupply: ${totalSupply.toString()}`,
        )
        setMeta({ name, symbol, totalSupply, value })
        console.log(`schemes: ${schemes}`)
        console.log(schemes)
        console.log(`ADDITION_SCHEME: ${ADDITION_SCHEME}`, ADDITION_SCHEME)
        console.log(
          `PERCENTAGE_SCHEME: ${PERCENTAGE_SCHEME}`,
          PERCENTAGE_SCHEME,
        )
        console.log(
          `MULTIPLIER_SCHEME: ${MULTIPLIER_SCHEME}`,
          MULTIPLIER_SCHEME,
        )
      } catch (err) {
        console.log('Error: ', err)
      }
    }
    fetchMeta()
  }, [contract, walletID])

  return { createScheme, ...meta }
}
