import { Table } from '../features/marketplace/Table'
import { ICreateScheme } from '../features/marketplace/scheme'

interface IProps extends ICreateScheme {
  symbol: string
}

export function MarketplacePage({ createSmartContractScheme, symbol }: IProps) {
  return (
    <>
      <Table
        symbol={symbol}
        createSmartContractScheme={createSmartContractScheme}
      />
    </>
  )
}
