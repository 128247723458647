import axios from 'axios'
import { IAWSScheme } from '../features/marketplace/scheme'

const API_URL = process.env.REACT_APP_API || ''

export const getProducts = async () => {
  const response = await axios.get<IAWSScheme[]>(`${API_URL}/schemes`)
  return response
}

export const deleteProducts = async (id: string) => {
  const response = await axios.delete<string>(`${API_URL}/scheme/${id}`)
  return response.data
}

export const editProducts = async (awsScheme: IAWSScheme) => {
  const response = await axios.put<IAWSScheme>(`${API_URL}/scheme`, awsScheme, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export const createProducts = async (data: IAWSScheme) => {
  const response = await axios.post<IAWSScheme[]>(`${API_URL}/scheme`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}
