import { FC } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from '@apollo/client'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import { Layout } from './Layout'
// Import Amplify and Storage
import Amplify from 'aws-amplify'
// withAuthenticator is a higher order component that wraps the application with a login page
import { withAuthenticator } from '@aws-amplify/ui-react'
// Import the project config files and configure them with Amplify
import awsconfig from './aws-exports'
import './App.less'
Amplify.configure(awsconfig)

const uri =
  process.env.REACT_APP_SUBGRAPH_MODE === 'production' &&
  process.env.NODE_ENV === 'production'
    ? `https://gateway.thegraph.com/api/${process.env.REACT_APP_THEGRAPH_API_KEY}/subgraphs/id/${process.env.REACT_APP_SUBGRAPH_ID}`
    : process.env.REACT_APP_SUBGRAPH_LOCAL
const client = new ApolloClient({
  uri,
  cache: new InMemoryCache(),
})

const App: FC = () => (
  <ApolloProvider client={client}>
    <ConfigProvider locale={enUS}>
      <Layout />
    </ConfigProvider>
  </ApolloProvider>
)

export default withAuthenticator(App)
