import { ParamsType } from '@ant-design/pro-provider'
import { IAWSScheme } from '../schemes/scheme'

export const sanitizeData = (
  data: IEvent[],
  schemesData: IAWSScheme[],
  challangeData: IChallenges[],
  params: ParamsType & {
    pageSize?: number
    current?: number
    keyword?: string
  },
) => {
  return data
    .filter((valueToFilter) => {
      const fiterKeys = Object.keys(params)
      let shouldReturnName = true
      let shuldReturnToken = true
      let shouldReturnDateOfActivation = true
      let shouldReturnScheme = true
      let shouldReturnGame = true

      if (fiterKeys.includes('name') && params['name'].length) {
        shouldReturnName = valueToFilter.name
          .toLowerCase()
          .includes(params['name'].toLowerCase())
      }
      if (
        fiterKeys.includes('triggerEventStatus') &&
        typeof params['triggerEventStatus'] === 'number'
      ) {
        console.log(params['triggerEventStatus'])
        shuldReturnToken =
          valueToFilter.triggerEventStatus.tokenReward ===
          params['triggerEventStatus']
      }

      if (
        fiterKeys.includes('dateOfActivation') &&
        params['dateOfActivation'].length
      ) {
        shouldReturnDateOfActivation = valueToFilter.dateOfActivation.includes(
          params['dateOfActivation'],
        )
      }
      // if (fiterKeys.includes('schemes') && params['schemes'].length) {
      //   console.log(valueToFilter.schemes, params['schemes'])
      //   shouldReturnScheme = valueToFilter.schemes.includes(params['schemes'])
      // }
      if (fiterKeys.includes('game') && params['game'].length) {
        shouldReturnGame = valueToFilter.game.name.includes(params['game'])
      }

      return (
        shouldReturnName &&
        shuldReturnToken &&
        shouldReturnDateOfActivation &&
        shouldReturnScheme &&
        shouldReturnGame
      )
    })
    .map((item) => {
      if (!schemesData.length) return item
      const currentEvent: IEvent = {
        ...item,
        schemes: item.schemes
          .map((currenScheme) => {
            const schemeData = schemesData.find(
              (scheme) => scheme.id === currenScheme,
            )
            return schemeData?.name || ''
          })
          .filter((currentScheme) => currentScheme.length > 0),
        challengeId:
          challangeData.find((challenge) => {
            return challenge.id === item.challengeId
          })?.name || '',
      }
      return currentEvent
    })
}