import { Tag, Typography, Statistic, Button } from 'antd'
import ProList from '@ant-design/pro-list'
import { EditOutlined } from '@ant-design/icons'

interface IProps {
  data: ITriggerEvent[]
  name: string
  symbol: string
  setDataSource: (data: ITriggerEvent[]) => void
}

export const GameEventList = ({
  data,
  setDataSource,
  name,
  symbol,
}: IProps) => {
  return (
    <ProList<ITriggerEvent>
      headerTitle="Can combine several triggers"
      toolBarRender={() => {
        return [
          <Statistic
            key="total"
            title={`Total ${name} (${symbol})`}
            value={data.reduce(
              (prev, curr) => prev + Number(curr.token || 0),
              0,
            )}
          />,
        ]
      }}
      dataSource={data}
      showActions="always"
      onDataSourceChange={setDataSource}
      metas={{
        title: {
          dataIndex: 'gameKey',
          title: 'Game Key',
          render: (text, record, index) => `Trigger # ${index + 1}`,
        },
        description: {
          dataIndex: 'value',
          render: (text, record, index) => (
            <>
              <Typography.Paragraph>
                <Typography.Text>
                  When trigger <Tag color="blue">{record.gameKey}</Tag>
                </Typography.Text>
              </Typography.Paragraph>
              <Typography.Paragraph>
                <Typography.Text>
                  and player value{' '}
                  <Tag color="cyan">{record.comparationMode ?? '='}</Tag> {text}
                </Typography.Text>
              </Typography.Paragraph>
            </>
          ),
        },
        content: {
          dataIndex: 'token',
          render: (text, record, index) => (
            <Typography.Text>
              Reward with <Tag>{text}</Tag>Tokens!
            </Typography.Text>
          ),
        },

        actions: {
          render: (text, row, index, action) => [
            <Button
              key="edit"
              icon={<EditOutlined />}
              onClick={() => {
                action?.startEditable(index)
              }}
            />,
          ],
        },
      }}
    />
  )
}