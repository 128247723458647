import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useUserSigner } from './useUserSigner'
import { useBalance } from 'eth-hooks'
import { useTokenBalance } from './useTokenBalance'
import { useContract } from './useContract'

const { REACT_APP_INFURA_ID = '' } = process.env

const web3Modal = new Web3Modal({
  network: 'matic',
  cacheProvider: true, // optional
  theme: 'dark', // optional. Change to "dark" for a dark theme.
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: REACT_APP_INFURA_ID,
        rpc: {
          1: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
          137: `https://polygon-mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`, // Matic
          80001: `https://polygon-mumbai.infura.io/v3/${REACT_APP_INFURA_ID}`, // Mumbai
        },
      },
    },
  },
})

const localProvider = new ethers.providers.StaticJsonRpcProvider(
  `https://polygon-mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`,
)
export function useWallet() {
  const [injectedProvider, setInjectedProvider] = useState<any>()
  const [address, setAddress] = useState<string>('')
  const [networkId, setNetworkId] = useState<number>(0)
  const { setAccount, ...token } = useTokenBalance()

  const userSigner = useUserSigner(injectedProvider, localProvider)
  const yourLocalBalance = useBalance(localProvider, address)
  const contract = useContract(injectedProvider, address)

  useEffect(() => {
    async function getAddress() {
      if (userSigner && injectedProvider) {
        const newAddress = await userSigner.getAddress()
        setAddress(newAddress)
        setAccount(newAddress)
      }
    }
    getAddress()
  }, [userSigner, injectedProvider])

  useEffect(() => {
    const getNetwork = async () => {
      console.log(userSigner)
      const provider = userSigner?.provider

      const network = await provider?.getNetwork()
      if (network?.chainId) setNetworkId(network.chainId)
    }
    getNetwork()
  }, [userSigner])

  const logoutOfWeb3Modal = useCallback(async () => {
    await web3Modal.clearCachedProvider()
    // connected now you can get accounts
    setAccount('')
    setAccount('')
    setInjectedProvider(null)

    if (typeof injectedProvider?.provider?.disconnect == 'function') {
      await injectedProvider.provider.disconnect()
    }

    setTimeout(() => {
      window.location.reload()
    }, 1)
  }, [injectedProvider])

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect()
    setInjectedProvider(new ethers.providers.Web3Provider(provider))
    // console.log(provider.on)
    provider.on('chainChanged', (chainId: number) => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    provider.on('accountsChanged', () => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    // Subscribe to session disconnection
    provider.on('disconnect', (code: number, reason: string) => {
      logoutOfWeb3Modal()
    })
  }, [setInjectedProvider])

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal()
    }
  }, [loadWeb3Modal])

  return {
    logoutOfWeb3Modal,
    loadWeb3Modal,
    address,
    selectedChainId: networkId,
    balance: yourLocalBalance,
    ...token,
    ...contract,
  }
}
