import React, { useCallback, useEffect, useState } from 'react'
import { Button, Divider, message } from 'antd'
import ProForm, {
  ModalForm,
  ProFormText,
  ProFormDigit,
  ProFormSelect,
  ProFormDatePicker,
  ProFormDateTimePicker,
} from '@ant-design/pro-form'
import { PlusOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'
import { GameEventsDynamicForm } from './GameEventsDynamicForm'
import { GameEventList } from './GameEventList'
import { ActionType } from '@ant-design/pro-table'
import {
  getGameRules,
  getSchemes,
  createGameEvent,
  editGameEvent,
  getChallenges,
} from '../../api'

interface IProps {
  action?: React.MutableRefObject<ActionType | undefined>
  type: "create" | "edit" | "duplicate";
  data?: IEvent
  name: string
  symbol: string
}

export const ManageEventModal = ({
  action,
  type,
  data,
  name,
  symbol,
}: IProps) => {
  const [gameOptions, setGameOptions] = useState<IOptions<number>[]>([])
  const [schemeOptions, setSchemeOptions] = useState<IOptions<string>[]>([])
  const [challengeOptions, setChallengeOptions] = useState<IOptions<string>[]>(
    [],
  )
  const [currentGameFeatures, setCurrentGameFeatures] = useState<Record<
    string,
    IGame
  > | null>(null)
  const [currentGameId, setCurrentGameId] = useState<number>(0)
  const [triggerEvents, setTriggerEvents] = useState<ITriggerEvent[]>([])
  const [disableGameSelect, setDisableGameSelect] = useState(false)

  useEffect(() => {
    if (type === 'edit' && data?.triggerEventStatus.events) {
      setTriggerEvents(data?.triggerEventStatus.events as ITriggerEvent[])
      setCurrentGameId(data.game.id)
    }
  }, [type, data])

  useEffect(() => {
    setDisableGameSelect(triggerEvents.length > 0)

    return () => {
      setDisableGameSelect(false)
    }
  }, [triggerEvents])

  const clearAll = () => {
    setCurrentGameId(0)
    setTriggerEvents([])
  }

  const createEvent = useCallback(
    async (dataForm: IEventForm) => {
      try {
        const game = gameOptions.find(
          (gameOption) => gameOption.value === dataForm.game,
        )

        const schemes = dataForm.schemes
  .map((scheme) => {
    const schemeMap = schemeOptions.find(
      (currentScheme) =>
        currentScheme.value === scheme || currentScheme.label === scheme
    );
    return schemeMap?.value || '';
  })
  .filter(
    (emptySchemes, position, schemesToFilter) =>
      emptySchemes.length > 0 &&
      schemesToFilter.indexOf(emptySchemes) === position
  );

  const matchingChallengeOption = challengeOptions.find(
    (challengeOption) =>
      challengeOption.value === dataForm.challengeId ||
      challengeOption.label === dataForm.challengeId
  );
  
  const challengeId = matchingChallengeOption ? matchingChallengeOption.value : '';



        console.log(dataForm, challengeId, challengeOptions)
        const eventToCreate: IEvent = {
          game: {
            id: game?.value ?? 0,
            name: game?.label ?? '',
          },
          // challengeId: challengeId?.value ?? '',
          challengeId: challengeId ?? '',
          schemes: schemes ?? [],
          triggerEventStatus: {
            tokenReward: triggerEvents.reduce(
              (prev, curr) => prev + (Number(curr.token) * 1e10),
              0,
            ),
            events: triggerEvents,
          },
          activationHours: dataForm.activationHours,
          dateOfActivation: dataForm.dateOfActivation,
          description: dataForm.description,
          name: dataForm.name,
        }
        console.log(eventToCreate)

        if (eventToCreate.schemes.length === 0)
          throw new Error('Schemes cannot be empty')
        if (type === 'create') await createGameEvent(eventToCreate)
        if (type === 'edit' && data?.id)
          await editGameEvent({ ...eventToCreate, id: data.id })

          if (type === "duplicate") {
            // Duplicate the event by creating a new event with the same data but a different name
            const duplicatedEvent: IEvent = {
              ...eventToCreate,
              name: `${eventToCreate.name} - copy`,
            };
            await createGameEvent(duplicatedEvent);
          }

        action?.current?.reload()
        clearAll()
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    [
      action,
      data,
      gameOptions,
      type,
      schemeOptions,
      challengeOptions,
      triggerEvents,
    ],
  )


  const handleDuplicate = async () => {
    if (type === 'edit' && data?.id) {
  
      const challengeId = challengeOptions.find(
        (challengeOption) => challengeOption.label === data.challengeId
      )?.value || '';      
  
      const schemes = data.schemes.map((schemeName) => {
        const schemeOption = schemeOptions.find(
          (schemeOption) => schemeOption.label === schemeName || schemeOption.value === schemeName
        );
        return schemeOption?.value || '';
      });
  
      const duplicatedEvent: IEvent = {
        ...data,
        id: undefined,
        name: `${data.name} - copy`,
        schemes: schemes,
        challengeId: challengeId,
      };
  
      await createGameEvent(duplicatedEvent);
      action?.current?.reload();
      message.success(`Duplicated event ${data.name}`);
    } else {
      message.error('Cannot duplicate this event');
    }
  };
  
  

  const getOptions = useCallback(async () => {
    try {
      const gameData = await getGameRules()
      const schemesData = await getSchemes()
      const challengesData = await getChallenges()

      const gameSelectOptions = Object.keys(gameData?.games || {}).map(
        (key) => ({
          value: Number(key),
          label: gameData?.games?.[key].meta.name,
        }),
      )
      const schemeSelectOptions = schemesData.data.map((scheme) => ({
        value: scheme.id,
        label: scheme.name,
      }))

      const challengeSelectOptions = challengesData.map((scheme) => ({
        value: scheme.id,
        label: scheme.name,
      }))

      setChallengeOptions(challengeSelectOptions)
      setCurrentGameFeatures(gameData.games)
      setGameOptions(gameSelectOptions)
      setSchemeOptions(schemeSelectOptions)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  return (
    <ModalForm<IEventForm>
  title={`${
    type === "create" ? "Create" : type === "edit" ? "Edit" : "Duplicate"
  } Game Event`}
  trigger={
    <Button
      type={type === "create" ? "primary" : "default"}
      size={type === "create" ? "middle" : "small"}
    >
      {type === "create"
        ? <PlusOutlined />
        : type === "edit"
        ? <EditOutlined />
        : <CopyOutlined />}
      {type === "create" ? "New" : type === "edit" ? "Edit" : "Duplicate"}
    </Button>
  }
  modalProps={{
    onCancel: clearAll,
    okText: "Save",
    destroyOnClose: true,
  }}
  onFinish={async (values) => {
    const result = await createEvent(values);
    if (result)
      message.success(
        `${values.name} successfully ${
          type === "create"
            ? "created"
            : type === "edit"
            ? "edited"
            : "duplicated"
        }`
      );
    else
      message.error(
        `error ${
          type === "create" ? "creating" : type === "edit" ? "editing" : "duplicating"
        } event ${values.name}, please try again`
      );
    return result;
      }}
    >
    {type === "edit" && (
      <ProForm.Item>
        <Button
          type="default"
          onClick={handleDuplicate}
          style={{ float: "right", marginBottom: "16px" }}
        >
          <CopyOutlined />
          Duplicate
        </Button>
      </ProForm.Item>
    )}
      <ProForm.Group>
        <ProFormText
          initialValue={data?.name || ''}
          preserve={false}
          width="md"
          name="name"
          label="Name"
          placeholder="Game event name..."
          rules={[{ required: true, message: 'Please select a name!' }]}
        />

        <ProFormText
          initialValue={data?.description || ''}
          preserve={false}
          width="md"
          name="description"
          label="Description"
          placeholder="Game event description ..."
          rules={[{ required: true, message: 'Please select a description!' }]}
        />
      </ProForm.Group>

      <ProForm.Group>
        <ProFormSelect
          initialValue={data?.game?.id || ''}
          preserve={false}
          options={gameOptions}
          disabled={disableGameSelect}
          width="md"
          name="game"
          label="Game"
          rules={[{ required: true, message: 'Please select a game!' }]}
          fieldProps={{
            onSelect: setCurrentGameId,
            onClear: () => setCurrentGameId(0),
          }}
        />

        <ProFormSelect
          initialValue={data?.schemes ?? []}
          preserve={false}
          width="md"
          options={schemeOptions}
          rules={[{ required: true, message: 'Please select a schemes!' }]}
          name="schemes"
          label="Schemes"
          mode="multiple"
        />
      </ProForm.Group>

      <ProForm.Group>
        <ProFormDatePicker
          initialValue={data?.dateOfActivation}
          dataFormat="YYYY-MM-DD"
          preserve={false}
          width="sm"
          name="dateOfActivation"
          label="Activation Date"
          rules={[{ required: true, message: 'Please select a date!' }]}
        />
        <ProFormDigit
          initialValue={data?.activationHours || 1}
          preserve={false}
          width="sm"
          label="Valid Hours"
          name="activationHours"
          placeholder="Activation Hours"
          rules={[
            { required: true, message: 'Please select a activation hours!' },
          ]}
          min={1}
          max={24}
        />
        <ProFormSelect
          initialValue={data?.challengeId || ''}
          preserve={false}
          options={challengeOptions}
          width="sm"
          name="challengeId"
          label="Challenge type"
          rules={[
            { required: true, message: 'Please select a challenge type!' },
          ]}
        />
      </ProForm.Group>

      <Divider>Trigger Events</Divider>
      <GameEventsDynamicForm
        data={currentGameFeatures}
        currentGame={currentGameId}
        appendTrigger={(trigger: ITriggerEvent) => {
          setTriggerEvents((currentTriggerEvents) => [
            ...currentTriggerEvents,
            trigger,
          ])
        }}
      />

      <GameEventList
        data={triggerEvents}
        setDataSource={setTriggerEvents}
        name={name}
        symbol={symbol}
      />
    </ModalForm>
  )
}