import axios from 'axios';
//import { IEvent } from './types'; // Import the IEvent interface if it's not already imported in your file

const API_URL = process.env.REACT_APP_API || '';

export const deleteGameEvent = async (id: string) => {
  const response = await axios.delete<string>(`${API_URL}/game-events/${id}`);
  return response.data;
};

export const editGameEvent = async (gameEvent: IEvent) => {
  const response = await axios.put<IEvent>(`${API_URL}/game-events`, gameEvent, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export const fetchGameEventById = async (id: string): Promise<IEvent> => {
  const response = await axios.get<IEvent>(`${API_URL}/game-events/${id}`); // Change <string> to <IEvent>
  return response.data;
};


export const duplicateGameEvent = async (id: string): Promise<IEvent> => {
  // Fetch the event by its ID
  const eventToDuplicate = await fetchGameEventById(id);

  // Create a new event object by duplicating the relevant fields
  const duplicatedEvent: IEvent = {
    ...eventToDuplicate,
    id: undefined, // Remove the ID so that the API can generate a new one
    name: `${eventToDuplicate.name} (Copy)`,
    description: `${eventToDuplicate.description} (Copy)`,
    // Add or modify any other fields that need to be duplicated or changed
  };
  // Send the duplicated event data to your API
  const response = await axios.post<IEvent[]>(`${API_URL}/game-events`, duplicatedEvent, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Return the first element of the response array (assuming the API returns an array with a single duplicated event)
  return response.data[0]
};

export const editAndDuplicateGameEvent = async (
  id: string,
  gameEventUpdates: Partial<IEvent>,
): Promise<IEvent> => {
  // Fetch the event by its ID
  const eventToEdit = await fetchGameEventById(id);

  // Apply updates to the event
  const updatedEvent: IEvent = {
    ...eventToEdit,
    ...gameEventUpdates,
  };

  // Edit the event with the provided updates
  await editGameEvent(updatedEvent);

  // Duplicate the edited event
  const duplicatedEvent = await duplicateGameEvent(id);

  return duplicatedEvent;
};

export const getGameEvents = async () => {
  const response = await axios.get<IEvent[]>(`${API_URL}/game-events`);
  return response;
};

export const createGameEvent = async (data: IEvent) => {
  const response = await axios.post<IEvent[]>(`${API_URL}/game-events`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};
