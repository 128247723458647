import { ActionType, ProColumns } from '@ant-design/pro-table'
import { Button, Tag } from 'antd'
import { ToolOutlined } from '@ant-design/icons'
import { ManageEventModal } from './ManageEventModal'
import { CopyOutlined } from '@ant-design/icons'
import { utils } from 'ethers'

import {
  editAndDuplicateGameEvent,
} from '../../api'

export const columns = (
  name: string,
  symbol: string,
  actionTable?: React.MutableRefObject<ActionType | undefined>,
) => {
  const data: ProColumns<IEvent>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      hideInSearch: true,
      editable: false,
      render: (record) => {
        const currentId = record as string

        return `${currentId.slice(0, 2)}...${currentId.slice(
          currentId.length - 4,
          currentId.length,
        )}`
      },
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: true,
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Name is required',
          },
        ],
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      filters: false,
      onFilter: true,
      hideInTable: true,
      hideInSearch: true,
      editable: false,
    },
    {
      title: 'Challenge',
      key: 'challenges',
      dataIndex: 'challengeId',
      hideInSearch: true,
      sorter: (a, b) => a.challengeId.localeCompare(b.challengeId),
      editable: false,
      render: (record) => {
        const challenge = record as IEvent
        return challenge
      },
    },
    {
      title: 'Schemes',
      dataIndex: 'schemes',
      editable: false,
      hideInSearch: true,
      width: '20%',
      sorter: (a, b) => a.schemes.length - b.schemes.length,
      render: (record) => {
        const currentSchemes = record as string[]

        return currentSchemes.map((scheme, index) => (
          <Tag key={index}>{scheme}</Tag>
        ))
      },
    },
    {
      title: 'Game',
      key: 'game',
      dataIndex: 'game',
      hideInSearch: false,
      sorter: (a, b) => a.game.name.localeCompare(b.game.name),
      editable: false,
      render: (record) => {
        const game = record as IEvent
        return game.name
      },
    },
    {
      title: 'Token Reward',
      key: 'triggerEventStatus',
      dataIndex: 'triggerEventStatus',
      valueType: 'digit',
      sorter: (a, b) =>
        a.triggerEventStatus.tokenReward - b.triggerEventStatus.tokenReward,
      hideInSearch: false,
      editable: false,
      render: (record) => {
        const token = record as { props: { text: { tokenReward: string } } }
        const tokenBalance = utils.formatUnits(token.props.text.tokenReward, 10)
        return `${tokenBalance} (${symbol})`
      },
    },
    {
      title: 'Date of activation',
      dataIndex: 'dateOfActivation',
      valueType: 'date',
      hideInTable: false,
      sorter: (a, b) =>
        new Date(a.dateOfActivation).getTime() -
        new Date(b.dateOfActivation).getTime(),
    },
    {
      title: 'Actions',
      valueType: 'option',
      render: (__, record, _, action) => [
        <Button
          key="editable"
          icon={<ToolOutlined />}
          size={'small'}
          onClick={() => {
            action?.startEditable?.(record?.id ?? '')
          }}
        />,
        <ManageEventModal
          key={'manage'}
          data={record}
          type="edit"
          action={actionTable}
          name={name}
          symbol={symbol}
        />,
      ],
    },
  ]
  return data
}