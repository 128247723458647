import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { getOrders } from '../../api/orders';
import { IAWSOrders } from './orders';

interface MyTableProps {
  onEdit: (order: IAWSOrders) => void;
  onDelete: (order: IAWSOrders) => void;
}

const MyTable: React.FC<MyTableProps> = ({ onEdit, onDelete }) => {
  const [data, setData] = useState<IAWSOrders[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getOrders();
        setData(data);
      } catch (error) {
        console.error('Error getting orders:', error);
      } finally {
        setLoading(false);
      }
    };    
    fetchOrders();
  }, []);
   

    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'orderId',
        key: 'orderId',
      },
      {
        title: 'Product ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      // {
      //   title: 'Logo Source',
      //   dataIndex: 'logoSrc',
      //   key: 'logoSrc',
      // },
      {
        title: 'User Email',
        dataIndex: 'userEmail',
        key: 'userEmail',
      },
      {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
      },
      {
        title: 'Order Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
      },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: IAWSOrders) => (
        <div>
          <Button type="primary" onClick={() => onEdit(record)}>
            Edit
          </Button>
          <Button danger onClick={() => onDelete(record)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return <Table dataSource={data} columns={columns} rowKey="orderId" loading={loading} />;
};

export default MyTable;