import { FC, useMemo, useState } from 'react'
import { Button, Divider, message, Typography } from 'antd'
import ProForm, {
  ModalForm,
  ProFormText,
  ProFormSelect,
  ProFormDigit,
  ProFormGroup,
} from '@ant-design/pro-form'
import { PlusOutlined } from '@ant-design/icons'
import ProCard from '@ant-design/pro-card'

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

interface IProps {
  currentGame: number
  data: Record<string, IGame> | null
  appendTrigger: (trigger: ITriggerEvent) => void
}

interface IPropsCurrentInput {
  name?: string
  type?: 'string' | 'number'
  values?: Record<string, string> | string[] | string | number[]
}

const CurrentInput = ({ type, name, values }: IPropsCurrentInput) => {
  if (type === 'string' && (typeof values === 'string' || !values)) {
    return <ProFormText width="md" name={name} label={name} preserve={false} />
  }
  if ((type === 'string' || type === 'number') && Array.isArray(values)) {
    return (
      <ProFormSelect
        options={values.map((value) => ({ value, label: value }))}
        width="md"
        name={name}
        label={name}
        preserve={false}
      />
    )
  }
  if (
    (type === 'string' || type === 'number') &&
    typeof values === 'object' &&
    !Array.isArray(values)
  ) {
    return (
      <ProFormSelect
        options={Object.keys(values).map((value) => ({
          value,
          label: values[value],
        }))}
        width="md"
        name={name}
        label={name}
        preserve={false}
      />
    )
  }
  if (type === 'number') {
    return (
      <ProFormDigit
        width="md"
        name={name}
        label={name}
        min={0}
        preserve={false}
      />
    )
  }
  return null
}

type TcomputeMode = '=' | '>' | '>=' | '<' | '<='

export const GameEventsDynamicForm: FC<IProps> = ({
  data,
  currentGame,
  appendTrigger,
}) => {
  const [currentGameKey, setCurrentGameKey] = useState('')

  const [computeMode, setComputeMode] = useState<TcomputeMode>('=')

  const game = useMemo(() => {
    return data?.[currentGame] ?? null
  }, [data, currentGame])

  const gameInfoKeys = useMemo(() => {
    debugger
    console.log(game)
    if (!game?.info)
      return {
        data: null,
        keys: [],
      }

    const infoKeys = []

    for (const key in game?.info) {
      infoKeys.push(key)
    }


    const subkeys = infoKeys
      .map((key) => game?.info[key])
      .reduce((acc, curr) => {
        return { ...acc, ...curr }
      }, {})

    return {
      data: subkeys,
      keys: Object.keys(subkeys).map((gameInfoSubKey) => ({
        label: gameInfoSubKey,
        value: gameInfoSubKey,
      })),
    }
  }, [game])

  const gameEventKeys = useMemo(() => {
    if (!game?.event) {
      return { data: null, keys: [] };
    }
  
    const eventKeys = Object.keys(game.event).map(eventKey => ({
      label: eventKey,
      value: eventKey,
    }));
  
    return {
      data: game.event,
      keys: eventKeys,
    };
  }, [game]);

  const combinedKeys = useMemo(() => {
    return [...gameInfoKeys.keys, ...gameEventKeys.keys];
  }, [gameInfoKeys.keys, gameEventKeys.keys]);

  const dynamicInput = useMemo(() => {
    // Check if the currentGameKey is from gameInfoKeys or gameEventKeys
    const isInfoKey = gameInfoKeys.keys.some(key => key.value === currentGameKey);
    const currentInput = isInfoKey
      ? gameInfoKeys.data?.[currentGameKey]
      : gameEventKeys.data?.[currentGameKey];
  
    // Update computeMode if necessary
    if (currentInput?.constraints.type !== 'number') setComputeMode('=');
  
    return currentInput;
  }, [currentGameKey, gameInfoKeys, gameEventKeys]);

  return (
    <ModalForm<Record<string, any>>
      title={`Add Trigger: ${game?.meta?.name ?? '...'}`}
      trigger={
        <Button type="primary">
          <PlusOutlined />
          Trigger
        </Button>
      }
      modalProps={{
        onCancel: () => {
          setCurrentGameKey('')
        },
      }}
      onFinish={async (values) => {
        await waitTime(2000)
        const gameTrigger: ITriggerEvent = {
          value: values[values.gameKey],
          gameKey: values.gameKey,
          path: dynamicInput?.constraints.path ?? [],
          token: values.token,
          comparationMode: values.comparationMode,
        }
        appendTrigger(gameTrigger)
        message.success('Trigger Event Added')
        return true
      }}
    >
      <ProCard split="vertical">
        <ProCard title="Overwolf Info" colSpan="40%">
          <ProForm.Group>
            <ProFormSelect
              options={combinedKeys}
              width="md"
              name="gameKey"
              label="Select a Game Info Key"
              fieldProps={{
                onSelect: setCurrentGameKey,
                onClear: () => setCurrentGameKey(''),
              }}
              preserve={false}
            />
            <ProFormDigit
              width="md"
              label="GMXP Token Reward"
              name="token"
              min={0}
              preserve={false}
            />

            <ProFormSelect
              width="md"
              showSearch
              help={`Example: player value "Equals ( = )" challenge value`}
              fieldProps={{
                value: computeMode,
                onChange: setComputeMode,
              }}
              options={[
                {
                  value: '=',
                  label: 'Equals ( = )',
                },
                {
                  value: '>',
                  label: 'Greather than ( > )',
                  disabled: dynamicInput?.constraints.type !== 'number',
                },
                {
                  value: '>=',
                  label: 'Greather than or Equal to ( >= )',
                  disabled: dynamicInput?.constraints.type !== 'number',
                },
                {
                  value: '<',
                  label: 'Less than (<)',
                  disabled: dynamicInput?.constraints.type !== 'number',
                },
                {
                  value: '<=',
                  label: 'Less than or Equal to (<=)',
                  disabled: dynamicInput?.constraints.type !== 'number',
                },
              ]}
              initialValue="="
              name="comparationMode"
              label="Comparation Mode"
              preserve={false}
            />
          </ProForm.Group>
        </ProCard>
        <ProCard title={'Details'} headerBordered>
          <Typography.Paragraph>
            <Typography.Text strong>
              About: {dynamicInput?.name ?? 'no details'}
            </Typography.Text>
          </Typography.Paragraph>
          <Divider> Constraints </Divider>
          <Typography.Paragraph>
            <Typography.Text strong>
              Type: {dynamicInput?.constraints.type}
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text strong>
              OW Path: {dynamicInput?.constraints.path.join('-> ')}
            </Typography.Text>
          </Typography.Paragraph>
          <Divider> Input </Divider>
          <ProFormGroup>
            <CurrentInput
              name={currentGameKey}
              type={dynamicInput?.constraints?.type}
              values={dynamicInput?.values}
            />
          </ProFormGroup>
        </ProCard>
      </ProCard>
    </ModalForm>
  )
}