import { Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

const createCSV = (data: Record<string, any>) => {
  const items = data
  const replacer = (key: string, value: string) => (value === null ? '' : value)
  const header = Object.keys(items[0] ?? {})
  if (header.length === 0) return ' '
  const csv = [
    header?.join(','),
    ...items?.map((row: string) =>
      header
        ?.map((fieldName: any) =>
          JSON.stringify(row[fieldName], replacer)?.replace(/\\"/g, '""'),
        )
        .join(','),
    ),
  ].join('\r\n\n')

  return csv
}

interface IProps {
  data: () => Promise<AxiosResponse>
  filename: string
}

export function DownloadCSVButton({ data, filename }: IProps) {
  const [dataToDownload, setDataToDownload] = useState('')

  useEffect(() => {
    const dataToDownload = async () => {
      if (data) {
        const dowloaded = await data()
        const virtualCSV = createCSV(
          dowloaded.data.map(
            (currentData: Record<string, any>, index: number) => ({
              ...currentData,
              index,
            }),
          ),
        )

        setDataToDownload(virtualCSV)
      }
    }
    dataToDownload()
    return () => {
      setDataToDownload('')
    }
  }, [data])

  if (!dataToDownload && !dataToDownload.length) return <Spin size="small" />
  return (
    <a
      download={`${filename}.csv`}
      target="_self"
      href={'data:text/csv;charset=utf-8,' + encodeURI(dataToDownload)}
    >
      <>
        <DownloadOutlined /> CSV
      </>
    </a>
  )
}
