import { gql } from '@apollo/client'

export interface Ireward {
  amount: string
  challengeId: string
  id: string
  player: string
  rewardedAt: string
}

export interface IrewardedGraphData {
  rewardeds: Ireward[]
}

export const REWARDEDS = gql`
  query {
    rewardeds(orderBy: id) {
      id
      player
      amount
      challengeId
      rewardedAt
    }
  }
`
