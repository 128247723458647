import axios from 'axios'
import { IAWSOrders } from '../features/orders/orders'
import { IAWSSchemes } from '../features/orders/orders'

const API_URL = process.env.REACT_APP_API || ''

export const getOrders = async () => {
  try {
    const response = await axios.get(`${API_URL}/orders`)
    const data = JSON.parse(response.data.body); // parse the JSON string
    console.log(data); // log the parsed data
    return data; // return the parsed data
  } catch (error) {
    console.error('Error getting orders:', error);
    throw error;
  }
}

export const deleteOrders = async (orderId: string) => {
  try {
    const response = await axios.delete<string>(`${API_URL}/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting order:', error);
    throw error;
  }
};  


export const editOrders = async (awsScheme: IAWSOrders) => {
  const response = await axios.put<IAWSOrders>(`${API_URL}/orders`, awsScheme, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export const createOrders = async (data: IAWSOrders): Promise<string | undefined> => {
  try {
    const response = await axios.post<IAWSOrders[]>(`${API_URL}/orders`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return 'Order created successfully'; // return a success message
    }
    return undefined;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getSchemes = async () => {
  const response = await axios.get<IAWSSchemes[]>(`${API_URL}/schemes`)
  return response
}

export const deleteScheme = async (id: string) => {
  const response = await axios.delete<string>(`${API_URL}/scheme/${id}`)
  return response.data
}

export const editScheme = async (awsScheme: IAWSSchemes) => {
  const response = await axios.put<IAWSSchemes>(`${API_URL}/scheme`, awsScheme, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export const createScheme = async (data: IAWSSchemes) => {
  const response = await axios.post<IAWSSchemes[]>(`${API_URL}/scheme`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}