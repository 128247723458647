import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import ProForm, { ProFormText, ProFormSelect, ProFormDateTimePicker } from '@ant-design/pro-form';
import { IAWSOrders, IAWSSchemes } from './orders';
import { getSchemes, getOrders } from '../../api/orders';
import { editOrders, createOrders } from '../../api/orders';

interface ManageEventModalProps {
  visible: boolean;
  onClose: () => void;
  currentOrder: IAWSOrders | undefined;
  createIWSScheme: (order: IAWSOrders, actionType: 'delete' | 'edit' | 'add') => Promise<void>;
}

interface IAWSOrdersExtended extends IAWSOrders {
  productKey?: string;
}

const ManageEventModal: React.FC<ManageEventModalProps> = ({ currentOrder, onClose }) => {
  const [initialValues, setInitialValues] = useState<IAWSOrdersExtended | undefined>(currentOrder || undefined);
  const [productKeys, setProductKeys] = useState<string[]>([]);

  useEffect(() => {
    if (currentOrder) {
      setInitialValues({
        ...currentOrder,
        productKey: currentOrder.ProductKey,
      });
    } else {
      setInitialValues(undefined);
    }
  }, [currentOrder]);    

  useEffect(() => {
    const loadProductKeys = async () => {
      try {
        const schemesResponse = await getSchemes();
        if (!schemesResponse || !schemesResponse.data) {
          console.log('No schemes data found');
          return;
        }
        const schemes = schemesResponse.data;
        console.log('Fetched schemes:', schemes);
  
        const ordersResponse = await getOrders();
        if (!ordersResponse) {
        console.log('No orders data found');
        return;
        }
        
        const orders = ordersResponse; // directly use the response

  
        const usedProductKeys = orders
          .filter((order: IAWSOrders) => order.ProductKey_Status === 'used')
          .map((order: IAWSOrders) => order.ProductKey);
        console.log('Used product keys:', usedProductKeys);
  
        const matchingSchemes = schemes.filter((scheme) => scheme.id === currentOrder?.id);
        console.log('Matching schemes:', matchingSchemes);
  
        if (matchingSchemes.length > 0) {
          const keys: string[] = [];
          matchingSchemes.forEach((matchingScheme) => {
            for (let i = 1; i <= 10; i++) {
              const key = `ProductKey${i}` as keyof IAWSSchemes;
              console.log('Checking key:', key, 'value:', matchingScheme[key]);
              if (matchingScheme[key]) {
                keys.push(matchingScheme[key] as string);
              }
            }
          });
          console.log('Extracted keys from matching schemes:', keys);
  
          const availableProductKeys = keys.filter((key) => !usedProductKeys.includes(key));
          console.log('Available product keys:', availableProductKeys);
          setProductKeys(availableProductKeys);
        } else {
          console.log('No matching schemes for current order id:', currentOrder?.id);
        }
      } catch (error) {
        console.error('Error loading product keys:', error);
      }
    };
    loadProductKeys();
  }, [currentOrder]);   

  const handleSubmit = async (values: IAWSOrders & { productKey: string }) => {
    const { productKey, ...otherValues } = values;

    const orderWithProductKey: IAWSOrders = {
      ...otherValues,
      ProductKey: productKey,
      ProductKey_timestamp: new Date().toISOString(),
      ProductKey_Status: 'used',
    };

    if (values.orderId) {
      await editOrders(orderWithProductKey);
    } else {
      await createOrders(orderWithProductKey);
    }

    onClose();
  };

  return (
    <Modal visible={true} onCancel={onClose}>
  {productKeys.length > 0 ? (
    <ProForm<IAWSOrdersExtended & { productKey: string }>
      initialValues={initialValues}
      onFinish={handleSubmit}
      submitter={{
        submitButtonProps: {
          children: initialValues?.orderId ? 'Update Order' : 'Create Order',
        },
      }}
    >
      <ProFormText name="orderId" label="Order ID" required disabled={true} />
      <ProFormText name="id" label="Product ID" required disabled={true} />
      <ProFormText name="name" label="Name" required disabled={!!initialValues?.name} />
      <ProFormText name="description" label="Description" required disabled={!!initialValues?.description} />
      {/* <ProFormText name="logoSrc" label="Logo Source" disabled={!!initialValues?.logoSrc} /> */}
      <ProFormText name="userEmail" label="User Email" required disabled={!!initialValues?.userEmail} />
      <ProFormText name="userName" label="User Name" disabled={!!initialValues?.userName} />
      <ProFormDateTimePicker
        name="timestamp"
        label="Order Timestamp"
        disabled={!!initialValues?.timestamp}
        required
        fieldProps={{
        format: "MM/DD/YYYY, h:mm:ss A"
        }}
      />
      <ProFormSelect
        name="productKey"
        label="Product Key"
        options={productKeys.map(key => ({ label: key, value: key }))}
        placeholder="Please select a product key"
      />
    </ProForm>
  ) : (
    <div>Loading product keys...</div>
  )}
</Modal>
    );
  };
  
  export default ManageEventModal;
  
