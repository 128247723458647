import { List, Tag, Typography } from 'antd'

export const ExpandedRowRender = (data: IEvent) => {
  return (
    <List
      size="small"
      header={data.description}
      bordered
      dataSource={data.triggerEventStatus.events ?? []}
      renderItem={(item, index) => (
        <List.Item key={item.id}>
          <Typography.Paragraph>
            <Typography.Text strong>Trigger # {index + 1}</Typography.Text>
            {' : '}
            <Typography.Text>
              When trigger <Tag color="blue">{item.gameKey}</Tag> and player
              value <Tag color="cyan">{item?.comparationMode ?? '='}</Tag>
              <Tag>{item.value}</Tag>
            </Typography.Text>
            <Typography.Text>
              Reward with <Tag>{item.token}</Tag> Token !
            </Typography.Text>
          </Typography.Paragraph>
        </List.Item>
      )}
    />
  )
}