import { List, Tag, Typography } from 'antd'
import { SchemeDataTable } from '../schemes/scheme'

const { Paragraph, Text } = Typography

export const ExpandedRowRender = (data: SchemeDataTable) => {
  return (
    <Paragraph>
      <Paragraph>
        <blockquote>{data.description}</blockquote>
      </Paragraph>
      <List
        size="small"
        header={data.description}
        bordered
        dataSource={data?.overwolfInfo ?? []}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Typography.Paragraph>
              <Typography.Text strong>Info # {index + 1}</Typography.Text>
              {' : '}
              <Typography.Text>{item.line}</Typography.Text>
            </Typography.Paragraph>
          </List.Item>
        )}
      />
    </Paragraph>
  )
}
