import { gql } from '@apollo/client'
import { ISmartContractScheme } from '../schemes/scheme'

export interface ISmartContractSchemeQuery {
  purchaseAmount: string
  schemeId: string
  schemeType: number
  schemeValue: string
  validThru: string
  expirationDays: string
}

export interface ISchemesData {
  schemes: ISmartContractScheme[]
}

export const SCHEMES = gql`
  query {
    schemes(orderBy: schemeId, orderDirection: asc) {
      schemeId
      schemeType
      schemeValue
      purchaseAmount
      validThru
      expirationDays
    }
  }
`
