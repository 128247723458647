import { ActionType, ProColumns } from '@ant-design/pro-table'
import { Image, Tag } from 'antd'
import { ProductDataTable } from '../marketplace/scheme'
import { ISmartContractScheme } from '../marketplace/scheme'

import { ManageEventModal } from './ManageEventModal'

import { utils } from 'ethers'
//import Decimal from 'decimal.js';

const SCHEMES_NAME = {
  1: 'Addition',
  2: 'Percentage',
  3: 'Multiplier',
  4: 'Services',
}



const sorter = (a: ProductDataTable, b: ProductDataTable) => {
  const aValue = a.schemeValue;
  const bValue = b.schemeValue;

  if (aValue < bValue) {
    return -1;
  }

  if (aValue > bValue) {
    return 1;
  }

  return 0;
};


export const columns = (
  symbol: string,
  createSmartContractScheme: (
    scheme: ISmartContractScheme,
    type: 'add' | 'update',
  ) => Promise<string | undefined>,
  reloadTable: () => Promise<void>,
  actionTable?: React.MutableRefObject<ActionType | undefined>,
) => {
  const data: ProColumns<ProductDataTable>[] = [
    {
      title: 'ID',
      dataIndex: 'schemeId',
      hideInSearch: true,
      editable: false,
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: true,
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Name is required',
          },
        ],
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'scheme_quantity',
      sorter: (a, b) => a.scheme_quantity - b.scheme_quantity,
      ellipsis: true,
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Quantity is required',
          },
        ],
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      filters: false,
      onFilter: true,
      hideInTable: true,
      hideInSearch: true,
      editable: false,
    },
    {
      title: 'Logo',
      dataIndex: 'logoSrc',
      editable: false,
      hideInSearch: true,
      render: (url) => {
        return url !== 'no-image' ? (
          <Image src={url as string} alt="" width={30} height={30} />
        ) : null
      },
    },
    {
      title: 'Type',
      key: 'schemeType',
      dataIndex: 'schemeType',
      valueType: 'text',
      sorter: (a, b) => Number (a.schemeValue) - Number(b.schemeValue),
      hideInSearch: false,
      editable: false,
      render: (scheme, v) => {
        return (
          <>
            <Tag color={scheme === 3 ? 'cyan' : 'default'}>{`${
              SCHEMES_NAME[scheme as 1 | 2 | 3 | 4]
            }`}</Tag>
            <Tag>{`${v.schemeValue}`}</Tag>
          </>
        )
      },
    },
    {
      title: 'Purchase Amount',
      key: 'value',
      dataIndex: 'purchaseAmount',
      valueType: 'digit',
      sorter: (a, b) => Number(a.purchaseAmount) - Number(b.purchaseAmount),
      hideInSearch: false,
      editable: false,
      render: (purchaseAmount: any) => {
        // console.log(purchaseAmount.props.text)
        const balance = purchaseAmount as { props: { text: string } }
        const tokenBalance = utils.formatUnits(balance.props.text, 10)
        return `${tokenBalance} (${symbol})`
      },
    },
    {
      title: 'Valid Thru',
      dataIndex: 'validThru',
      valueType: 'date',
      hideInTable: false,
      editable: false,
      sorter: (a, b) =>
        new Date(Number(a.validThru)).getTime() -
        new Date(Number(b.validThru)).getTime(),
      render: (date) => {
        //@ts-ignore
        return new Date(Number(date.props.text)).toLocaleDateString()
      },
    },
    {
      title: 'Expiration Days',
      dataIndex: 'expirationDays',
      valueType: 'digit',
      hideInTable: false,
      editable: false,
      sorter: (a, b) =>
        new Date(Number(a.expirationDays)).getTime() -
        new Date(Number(b.expirationDays)).getTime(),
    },
    {
      title: 'Actions',
      valueType: 'option',
      render: (__, record, _, action) => [
        <ManageEventModal
          createSmartContractScheme={createSmartContractScheme}
          symbol={symbol}
          key={'manage'}
          data={record}
          type="edit"
          action={actionTable}
          reloadTable={reloadTable}
        />,
        // <Button
        //   key="editable"
        //   icon={<ToolOutlined />}
        //   size={'small'}
        //   onClick={() => {
        //     action?.startEditable?.(record?.id ?? '')
        //   }}
        // />,
      ],
    },
  ]
  return data
}